<template>
    <div class="ui grid">
        <div class="five column row centered">
            <ol class="c-stepper">
                <li v-for="step in steps"
                    :class="['c-stepper__item',
				    {'current': step._id === current_step._id},
				    {'complete': isStepValidated(step._id)},
				    {'reject': isStepRejected(step._id)}]"
                >
                    <i v-if="isStepValidated(step._id)" class="ui icon check large icon-step"/>
                    <i v-else-if="isStepRejected(step._id)" class="ui icon x large icon-step"/>
                    <i v-else-if="step._id === current_step._id" class="ui icon refresh large icon-step"/>

                    <h3 class="c-stepper__title">{{step.label}}</h3>
                    <p v-show="step._id === current_step._id && !isStepRejected(step._id) " class="c-stepper__desc">
                        {{$SHARED.utils.getDateOnly(step.date_limit)}}</p>
                    <p v-if="isStepValidated(step._id)" class="c-stepper__desc">{{stepDateValidation(step._id)}}</p>
                    <p v-if="isStepRejected(step._id)" class="c-stepper__desc">{{stepDateRejection(step._id)}}</p>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
    export default {
        name: "suiviDemandes.horizontalProcess",
        computed: {
            steps() {
                return this.$store.state.steps;
            },
            current_step() {
                return this.$store.getters.current_step;
            },
            validated_steps() {
                return this.$store.getters.validated_steps;
            }
        },
        methods: {
            isStepValidated(step_id) {
                return this.validated_steps.filter(step => step.step_id === step_id).length > 0
            },
            isStepRejected(step_id) {
                return step_id === this.current_step._id && this.current_step.status === 'rejected'
            },
            stepDateValidation(step_id) {
                return this.isStepValidated(step_id)
                    ? this.$SHARED.utils.getDate(this.validated_steps.filter(step => step.step_id === step_id)[0]?.date_validation, 'DD/MM/YYYY')
                    : ''
            },
            stepDateRejection(step_id) {
                return this.isStepRejected(step_id)
                    ? this.$SHARED.utils.getDate(this.current_step.date_rejection, 'DD/MM/YYYY')
                    : ''
            }
        }
    }
</script>

<style scoped>

    .c-stepper {
        display: flex;
        flex-wrap: wrap;
    }

    .c-stepper__item {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .c-stepper__item:before {
        --size: 3rem;
        content: "";
        position: relative;
        z-index: 1;
        display: block;
        width: var(--size);
        height: var(--size);
        background-color: var(--main-color-blue);
        border-radius: 50%;
        margin: 1rem auto 0;
    }

    .c-stepper__item.complete:before {
        background-color: var(--main-color-green);

    }

    .c-stepper__item.complete .c-stepper__title {
        color: var(--main-color-green);
        font-weight: 700;
    }

    .c-stepper__item.complete .c-stepper__desc {
        color: var(--main-color-green);
    }

    .c-stepper__item.current:before {
        background-color: var(--main-color-orange);

    }

    .c-stepper__item.current .c-stepper__title {
        color: var(--main-color-orange);
        font-weight: 700;
    }

    .c-stepper__item.current .c-stepper__desc {
        color: var(--main-color-orange);
    }


    li.step.complete::before {

        background: var(--main-color-green);

    }

    li.step.complete .title {

        color: var(--main-color-green);

    }

    li.step.complete .date {

        color: var(--main-color-green);

    }

    .c-stepper__item.reject::before {

        background: var(--main-color-red) !important;

    }

    .c-stepper__item.reject .c-stepper__title {

        color: var(--main-color-red) !important;

    }

    .c-stepper__item.reject .c-stepper__desc {

        color: var(--main-color-red) !important;

    }

    .c-stepper__item:after {
        content: "";
        position: relative;
        top: 2.5rem;
        left: 50%;
        height: 2px;
        background-color: var(--main-color-blue-dark);
        order: -1;
    }

    .c-stepper__item:last-child:after {
        width: 0;
    }

    .c-stepper__title {
        margin-top: 10px;
        margin-bottom: 0em;
        font-weight: 400;
        line-height: 1.5;
        color: var(--main-color-blue);
        text-transform: capitalize;
    }

    .c-stepper__desc {
        font-weight: 500;
    }

    .c-stepper__item {
        position: relative;
    }

    .icon-step {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
        color: white;
        display: block;
        width: var(--size);
        height: var(--size);
        --size: 3rem;
        top: 0.9rem !important;
        padding-top: 1rem !important;
    }

</style>
